const images = [
    "acorn",
    "aura",
    "bananapeel",
    // "bubble",
    "button",
    "cloud",
    "daisy",
    "dandelion",
    "diamond",
    "dust",
    "feather",
    "glass",
    "ice",
    "lake",
    "mango",
    "moon",
    "moth",
    "opal",
    "palms",
    "paperclip",
    // "pointing",
    "pomegranate",
    "rainbow",
    "sand",
    "scribble",
    // "sequin",
    "seashell",
    // "sillybandz",
    "snail",
    // "swallow",
    "twig",
    "waterdrops",
    "woodchips",
    "worm",
    "bigdip",
    "sunset",
    "pencil",
    // "peaches",
    "penny",
    "eightball",
    "yarn",
    "thread",
    "poker",
    "peppermint",
    // "screw",
    // "moodring",
    "dice",
    "beaniebaby",
    "chess",
    "egg",
    "blueberry",
    "pistachio",
    "kiss",
    "orange",
]

export {images}