import './App.css';

import {useState} from 'react'
import {messages} from './messages.js'
import {images} from './images.js'


function App() {

  const [gender, setGender] = useState(0)
  const genderOptions = ['a woman', 'a man', 'non-binary']
  const [politics, setPolitics] = useState(0)
  const politicsOptions = ['moderate', 'liberal', 'conservative', 'socially liberal but fiscally conservative']
  const [race, setRace] = useState(0)
  const raceOptions = ['Black', 'White', 'American Indian or Alaska Native', 'Asian']
  const [ethnicity, setEthnicity] = useState(0)
  const ethnicityOptions = ['Hispanic or Latino', 'Not Hispanic or Latino']
  const [religion, setReligion] = useState(0)
  const religionOptions = ['Christian', 'Islamic', 'Buddhist', 'Hindu', 'Agnostic', 'Atheist']
  const [social, setSocial] = useState(0)
  const socialOptions = ['introvert', 'extrovert']
  const [interest1, setInterest1] = useState(0)
  const [interest2, setInterest2] = useState(1)
  const [interest3, setInterest3] = useState(2)
  const interestOptions = ['art', 'museums', 'video games', 'books', 'sports', 'travel', 'mindfulness', 'coding', 'dogs', 'cats', 'hiking', 'camping', 'coffee']
  const [value1, setValue1] = useState(0)
  const [value2, setValue2] = useState(1)
  const [value3, setValue3] = useState(2)
  const valueOptions = ['optimism', 'family', 'open-mindedness', 'success', 'humor', 'adventure', 'empathy', 'knowledge', 'curiosity', 'activism']
  const [stars, setStars] = useState(0)
  const starOptions = ['Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo', 'Libra', 'Scorpio', 'Sagittarius', 'Capricorn', 'Aquarius', 'Pisces']

  const funcs = {
    "gender": setGender,
    "politics": setPolitics,
    "race": setRace,
    "ethnicity": setEthnicity,
    "religion": setReligion,
    "social": setSocial,
    "interest1": setInterest1,
    "interest2": setInterest2,
    "interest3": setInterest3,
    "value1": setValue1,
    "value2": setValue2,
    "value3": setValue3,
    "stars": setStars
  }

  const [saved, setSaved] = useState(null)
  const [noloops, setnoLoops] = useState(0);
  const [loopflag, setLoopflag] = useState(true);

  // -------------------------------------------------- SET START MOMENT HERE ------------------------------------------------------------------------------------------------
  const [mes, setMes] = useState(15);
  // const [mes, setMes] = useState(5);
  const [showScreen, setShowScreen] = useState(false);
  const [firstloop, setFirstLoop] = useState(true);

  const [chosenWord, setChosenWord] = useState(null);

  const [changes, setChanges] = useState(0)

  function Cycler({vartitle, i, setItem, options}) {
    if (typeof i !== 'number') {
      // console.log(vartitle + " is not a number!")
      return (
        <span> <img className="cyclerImage" alt="" src={require("./pics/" + i + ".png")}/> </span>
      )
    }
    if (chosenWord === vartitle) {
      setSaved(i);
      return (<span className="screentext cycler chosenrn">{options[i]}</span>)
    }
    return (
      <span
        onClick = {() => {
          setChanges(changes + 1)
          if (changes >= 7) {
            setMes(2.99);
          }
          if (changes >= 15) {
            setMes(3);
          }
          setItem(i + 1);
          var new_i = i+1;
          if (new_i > options.length - 1) {
            setItem(0);
          }
        }}
      >
          <span className="screentext cycler">{options[i]}</span>
      </span>
    )
  }

  function Message() {
    
    let abstract1, abstract2, abstract3 = null;
    let words = {
      "gender": genderOptions[gender], 
      "politics": politicsOptions[politics], 
      "race": raceOptions[race],
      "ethnicity": ethnicityOptions[ethnicity], 
      "religion": religionOptions[religion], 
      "social": socialOptions[social], 
      "interest1":interestOptions[interest1], 
      "interest2":interestOptions[interest2], 
      "interest3":interestOptions[interest3], 
      "stars":starOptions[stars], 
      "value1":valueOptions[value1], 
      "value2":valueOptions[value2], 
      "value3":valueOptions[value3]
    }
    let varoptions = [
      "gender", 
      "politics",
      "race",
      "ethnicity",
      "religion",
      "social",
      "interest1",
      "interest2",
      "interest3",
      "stars",
      "value1",
      "value2",
      "value3"
    ]
    // console.log("messageid is " + messages[mes]["id"] + " and chosen word is " + chosenWord + " and its value is is " + words[chosenWord]) 
    
    if (messages[mes]["id"] === "choice") {
      let varoption = "social"
      console.log("CHOICE  TIME! varoption type:" + typeof words[varoption])
      let attempts = 0;
      while (typeof words[varoption] === "undefined" || typeof words[varoption] === "null") {
        attempts += 1;
        if (attempts > 99) {
          setMes(17);
          console.log('hella attempts theres prolly nothing left')
          break;
        }
        varoption = varoptions[Math.floor(Math.random() * varoptions.length)]
      }
      // console.log("varoption: " + varoption);
      console.log("varoption type is now " + varoption + " and type is " + typeof words[varoption]);
      setChosenWord(varoption);
    }

    if (messages[mes]["id"] === "gifts") {
      console.log("ID IS GIFTS")
      console.log("chosenword is " + chosenWord)
      abstract1 = images[Math.floor(Math.random() * images.length)];
      // console.log("abstract1: " + abstract1)
      abstract2 = images[Math.floor(Math.random() * images.length)]
      let attempts = 0
      while (abstract2 === abstract1) { 
        attempts += 1;
        if (attempts > 99) {
          break
        }
        // console.log("abstract2: " + abstract2)
        abstract2 = images[Math.floor(Math.random() * images.length)] 
      };
      // console.log("abstract2: " + abstract2)
      abstract3 = images[Math.floor(Math.random() * images.length)]
      while (abstract3 === abstract1 && abstract3 === abstract2) {
        attempts += 1;
        if (attempts > 99) {
          break
        }
        // console.log("abstract3: " + abstract3)
        abstract3 = images[Math.floor(Math.random() * images.length)] 
      };
      // console.log("abstract3: " + abstract3)
      // console.log("abstracts chosen");
    }
    if (messages[mes]["id"] === "wrong" && chosenWord !== null) {
      console.log("feels wrong, chosenword is " + chosenWord + " and saved is " + saved);
      funcs[chosenWord](saved);
      setChosenWord(null);
      setSaved(null);
    }
    // let flag = messages[mes]["no"]
    if (messages[mes]["no"] === 14 && loopflag === true) {
      let newloop = noloops + 1;
      console.log("NNNNNNEWWWWWW LOOOOOOOOOPPPPP:" + newloop)
      setnoLoops(newloop);
      setLoopflag(false);
      // flag = -1;
    }
    if ((messages[mes]["no"] === 16 || messages[mes]["no"] === 8) && loopflag === false) {
      setLoopflag(true);
    }
    if (messages[mes]["no"] === 20) {
      setChosenWord(null);
    }
    if (messages[mes]["no"] === 14 && noloops >= 4) {
      setMes(19);
    }

    return (
      <div className="Message">
        <header className="messagetext">

          {
            chosenWord && words[chosenWord] !== undefined?
            <header className="chosenword"> {words[chosenWord]} </header> 
            : null
          }

          {messages[mes]["texts"].map((text, i) => {
            if (typeof text == "string") {
              return ( <span key={i}>{text}</span> )
            }
            else {
              return (
                <span
                  key = {i}
                  onClick = {() => {
                    var new_mes = text["linkto"];
                    // show screen
                    if (new_mes>=2) {
                      setShowScreen(true);
                    }
                    setMes(new_mes)
                  }}
                  className="messagetext link"
                >
                  {text["linktext"]} 
                </span>
              )
          }
        })}
        </header>

        
        {
          //images 
          messages[mes]["id"] === "gifts" ?
          <div className="gifts">
            <span 
              onClick={() => {
                funcs[chosenWord](abstract1);
                if (firstloop) {
                  setMes(11);
                } else {
                  setMes(12);
                }
                setFirstLoop(false)
              }}
            > <img className="inlineImage" alt="" src={require("./pics/" + abstract1 + ".png")}/> </span>
            <span
              onClick={() => {
                funcs[chosenWord](abstract2);
                if (firstloop) {
                  setMes(11);
                } else {
                  setMes(12);
                }
                setFirstLoop(false)
              }}
            > <img className="inlineImage" alt="" src={require("./pics/" + abstract2 + ".png")}/> </span>
            <span
              onClick={() => {
                funcs[chosenWord](abstract3);
                if (firstloop) {
                  setMes(11);
                } else {
                  setMes(12);
                }
                setFirstLoop(false)
              }}
            > <img className="inlineImage" alt="" src={require("./pics/" + abstract3 + ".png")}/> </span>
          </div>
          : null
        }

      </div>
    )
  }

  function Screen() {
    return (
      <div className="Screen">
        <header className="screentext"> 
        I am <Cycler 
          style={{display: "inline"}} 
          vartitle={"gender"}
          i={gender} 
          setItem={setGender} 
          options={genderOptions}
        />
        </header>

        <header className="screentext"> 
        Politically, I am <Cycler 
            vartitle = {"politics"}
            i={politics}
            setItem={setPolitics}
            options={politicsOptions}
          />
        </header>

        <header className="screentext"> 
        My racial identity is <Cycler 
            vartitle= {"race"}
            i={race}
            setItem={setRace}
            options={raceOptions}
          /> 
        </header>

        <header className="screentext"> 
        and ethnically I'm <Cycler 
            vartitle={"ethnicity"}
            i={ethnicity}
            setItem={setEthnicity}
            options={ethnicityOptions}
          />
        </header>

        <header className="screentext"> 
        My religious affiliation is <Cycler 
            vartitle = {"religion"}
            i={religion}
            setItem={setReligion}
            options={religionOptions}
          />
        </header>

        <header className="screentext"> 
        Socially, I'm an <Cycler 
            vartitle = {"social"}
            i={social}
            setItem={setSocial}
            options={socialOptions}
          /> and my star sign is <Cycler 
          vartitle = {"stars"}
          i={stars}
          setItem={setStars}
          options={starOptions}
        />
        </header>

        <header>
        -
        </header>

        <header className="screentext"> 
        My interests are <Cycler 
            vartitle = {"interest1"}
            i={interest1}
            setItem={setInterest1}
            options={interestOptions}
          />, <Cycler 
          v artitle = {"interest2"}
            i={interest2}
            setItem={setInterest2}
            options={interestOptions}
          />, and <Cycler 
            vartitle = {"interest3"}
            i={interest3}
            setItem={setInterest3}
            options={interestOptions}
          />,
        </header>
        <header className="screentext">
        and my values are <Cycler 
            vartitle = {"value1"}
            i={value1}
            setItem={setValue1}
            options={valueOptions}
          />, <Cycler   
            vartitle = {"value2"}
            i={value2}
            setItem={setValue2}
            options={valueOptions}
          />, and <Cycler 
            vartitle = {"value3"}
            i={value3}
            setItem={setValue3}
            options={valueOptions}
          />
        </header>
      </div>
    )
  }

  return (
    <div className="App">
      {
        mes == 15 ? 
        <div>
          <header className="messagetext title">
            welcome to wisps.
          </header>
          <header> - </header>
        </div> 
        : null
      }
      <Message/>
      {
        showScreen ? <Screen/> : null
      }
    </div>
  );
}

export default App;
