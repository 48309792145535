const messages = {
    0: {
        no: 0, id: "start",
        texts: [
            "i had a strange ",
            {
                linktext: "dream",
                linkto: 1
            },
            " the other day"
        ],
    },
    1: {
        no: 1, id: "dream-answer",
        texts : [
            "i was filling out a form or something, full of dropdowns and multiple choice. i bubbled in every ",
            {
                linktext: "answer",
                linkto: 2
            },
            "."
        ],
    },
    2: {
        no: 2, id: "filling in form",
        texts: [
            'i was filling out a form or something, full of dropdowns and multiple choice. i bubbled in every answer',
            {
                linktext: "",
                linkto: 2.99
            },
            ".",
        ],
    },
    2.99: {
        no: 2.99, id: "filling in form",
        texts: [
            "as i filled out each section, some of the choices felt weird. like, are these really the only options? i kept filling it in anyway."
        ]
    },
    3: {
        no: 3, id: "enter crow",
        texts: [
            "as i filled out each section, a bird flew in through the window. a crow. she sat and watched until i was ",
            {
                linktext: "finished",
                linkto: 4
            },
            " filling out the form.",
        ]
    },
    4: {
        no: 4, id: "perch",
        texts: [
            "as i went to submit the form, i took a look at her. she perched on my table and looked at the ",
            {
                linktext: "screen",
                linkto: 5
            },
            " then back at me.",
        ]
    },
    5: {
        no: 5, id: "taking word",
        texts: [
            "after studying my choices-- can birds even do that?-- she began to peck at the screen, taking the letters of one of the ",
            {
                linktext: "words",
                linkto: 6
            },
            " into her beak."
        ]
            
    },
    6: {
        no: 6, id: "choice",
        texts: [
            "seeing the letters dangling from her beak, the ",
            {
                linktext: "silliness",
                linkto: 6.5
            },
            " of the word struck me. is there anyone on planet earth who's JUST an introvert or extrovert? It felt so arbirary, so binary, so... pointless.",
        ]
    },
    6.5: {
        no: 6.5, id: "choice",
        texts: [
            "she moved to leave out the window, the word still in her mouth. a choice crossed my mind: should i stop her",
            // {
            //     linktext: "stop her",
            //     linkto: 7
            // },
            ", or should i ",
            {
                linktext: "let her take it",
                linkto: 9
            },
            "?"
        ]
    },
    7: {
        no: 7, id: "take words back",
        texts: [
            "just as she was about to leave out the window, i stopped her. how was i supposed to tell her i wanted the letters back? do birds even ",
            {
                linktext: "understand",
                linkto: 8.1
            },
            " that kind of thing? i stuttered at her."
        ]
    },
    8.1: {
        no: 8.1, id: "understand",
        texts: [
            "before I could say anything, she tilted her head, then dropped the letters back into their place on the screen. she turned back to the  ",
            {
                linktext: "screen",
                linkto: 6
            },
            "."
        ]
    },
    8: {
        no: 8, id: "understand",
        texts: [
            "before I could say anything, she tilted her head, then dropped the letters back into their place on the screen. she turned back to the  ",
            {
                linktext: "screen",
                linkto: 14
            },
            "."
        ]
    },
    9: {
        no: 9, id: "let her go",
        texts: [
            "she perched on the window ledge, looked at me, then left. a few moments later, she returned, now with a small collection of ",
            {
                linktext: "gifts",
                linkto: 10
            },
            " carefully held in her beak."
        ]
    },
    10: {
        no: 10, id: "gifts",
        texts: [
            "she placed the three gifts on the table for me, as if giving me a choice of which one to accept in return for the word."
        ]
    },
    11: {
        no: 11, id: "bird has chosen a gift",
        texts: [
            "she filled the empty space of the word with the gift. honestly, the sentence made just as much sense either way. it was just as much ~me~ as the arbitrary label, maybe even more so. i ",
            {
                linktext: "laughed",
                linkto: 12
            },
            " a little.",
        ]
    },
    12: {
        no: 12, id: "right",
        texts: [
            "i accepted her gift. she looked at me, i looked at her. she turned back to the ",
            {
                linktext: "screen",
                linkto: 14
            },
            "."
        ]
    },
    13: {
        no: 13, id: "wrong",
        texts: [
            "i shook my head, trying to communicate a feeling i only barely understand to a bird. she flitted from the room, returned with the letters and placed them back in their place on the screen. she turned back to the ",
            {
                linktext: "screen",
                linkto: 14
            },
            "."
        ]
    },
    14: {
        no: 14, id: "choice",
        texts: [
            "she took the letters of a new word into her beak, then moved to leave out the window. i looked at the word and asked myself if the label was important to me, if it mattered. again, a choice crossed my mind: should i ",
            {
                linktext: "stop her",
                linkto: 8
            },
            ", or should i ",
            {
                linktext: "let her take it",
                linkto: 16
            },
            "?"
        ]    
    },
    15: {
        no: 15, id: "TITLESCREEN",
        texts: [
            "a mini interactive narrative about the absurdity of ",
            {
                linktext: "labels →",
                linkto: 0
            }
        ]
    },
    16: {
        no: 16, id: "gifts",
        texts: [
            "again, she took the letters and returned with a set of gifts for me to choose. i could either ",
            {
                linktext: "accept",
                linkto: 16
            },
            " one as i had done before, or i could ",
            {
                linktext: "reject",
                linkto: 14
            },
            " her offerings and ask for the letters back."
        ]
    },
    17: {
        no: 17, id: "no more",
        texts: [
            "she perched on my desk, but i had no more words to give her. no more labels. i smiled. she had set me free. there was nothing left to do but ",
            {
                linktext: "wake up.",
                linkto: 18
            }
        ]
    },
    18: {
        no: 18, id: "wake up, no text",
        texts: [
            ""
        ]
    },
    19: {
        no: 19, id: "14 but with option to wake up as well.",
        texts: [
            "again, she took a new word and prepared to leave. i had begun to understand the choices she was offering me. should i ",
            {
                linktext: "stop her",
                linkto: 8
            },
            ", should i ",
            {
                linktext: "let her take it",
                linkto: 16
            },
            ", or was i ",
            {
                linktext: "happy",
                linkto: 20
            }, 
            " with what was in front of me?"
        ]
    },
    20: {
        no: 20, id: "wake up",
        texts: [
            "i looked at the collection of words, images, feelings, thoughts, it all swirled together. i looked at her and smiled. there was nothing left to do but ",
            {
                linktext: "wake up",
                linkto: 18
            },
            "."
        ]
    },
}

export {messages}